$(function () {
    $(document).foundation();
    document.createElement("picture");
    $(".img2svg").img2svg();

    $(window).on("load", function () {
        $(".img2svg").img2svg();
    });
    // Desktop and mobile add or remove class
    $(window).on(
        "changed.zf.mediaquery resize.zf.mediaquery load.zf.mediaquery",
        function () {
            if (
                Foundation.MediaQuery.current == "small" ||
                Foundation.MediaQuery.current == "medium"
            ) {
                $("#mainnav > li").each(function () {
                    if ($(this).children("ul").length) {
                        $(this).addClass("has-children");
                        $(this).children("ul").removeClass("is-dropdown-submenu");
                    }
                });
            } else {
                $("body,html").removeClass("overflow-hidden");
                $("#nav-icon2").removeClass("open");
                $("#mainnav > li.has-children ul").removeAttr("style");
                $("#mainnav > li").each(function () {
                    if ($(this).children("ul").length) {
                        $(this).removeClass("has-children");
                        $(this).children("ul").addClass("is-dropdown-submenu");
                    }
                });
            }
        }
    );
    //Previously open nav close
    $("#nav-icon2").click(function () {
        setTimeout(function () {
            if ($("#mainnav > li.has-children").find("ul").is(":visible")) {
                $("#mainnav > li.has-children")
                    .removeClass("active")
                    .find("ul")
                    .slideUp();
            }
            $(".language-dropdown .selectedLang.active").trigger("click");
        }, 500);
    });
    $(".close-hamberger").click(function () {
        $("#nav-icon2").trigger("click");
    });
    //Desktop MouseHover add remove class
    $("#mainnav > li")
        .mouseenter(function () {
            $(".search-dropdown").slideUp();
            $("header .search-toggle").removeClass("search-open");
            $(".search-dropdown").attr("aria-hidden", true);
            $(".search-toggle").attr("aria-expanded", false);
            $(this).addClass("is-active");
        })
        .mouseleave(function () {
            $(this).removeClass("is-active");
        });
    //Mobile Accordian nav
    $(document).on("click", "#mainnav > li .arrow", function () {
        $(this).next("ul").slideToggle();
        $(this).parent("li").siblings().find("ul").slideUp();
        $(this).parent().toggleClass("active").siblings().removeClass("active");
        if ($(".lan-dropdown").is(":visible")) {
            $(".lan-dropdown").slideUp();
            $(".selectedLang").removeClass("active");
        }
        if ($(this).attr("aria-expanded") == "false") {
            $(this).attr("aria-expanded", true);
            $(this).next("ul").attr("aria-hidden", false);
        } else {
            $(this).attr("aria-expanded", false);
            $(this).next("ul").attr("aria-hidden", true);
        }
    });
    //Mobile Acive Nav scroll to top
    $("#mainnav").on("click", "li.has-children", function () {
        $(this).each(function () {
            $panel = $(this);
            $("#mainnav").animate(
                {
                    scrollTop: $panel.position().top,
                },
                500
            );
        });
    });
    $("#mainnav").on("click", ".is-accordion-submenu-parent > a", function () {
        $(this).each(function () {
            $panel = $(this).closest("li");
            $("#mainnav").animate(
                {
                    scrollTop: $panel.position().top,
                },
                500
            );
        });
    });
    /*Search*/
    $(".search, .search input").on("click", function (event) {
        event.stopPropagation();
    });
    $(".search").on("click", function (event) {
        $(this).toggleClass("active");
        $(".search input").focus();
    });
    $("body,html").on("click", function () {
        $(".search").removeClass("active");
    });
    /*Search Ends*/
    /*language toggle*/
    $(".langSelected").on("click", function (event) {
        event.preventDefault();
        var isoCode = $(this).attr("iso-code").toUpperCase();
        if (isoCode != null && isoCode != undefined) {
            $(this).parent().parent().parent().find("a.selectedLang").text(isoCode);
        }
        $(this)
            .parent()
            .parent()
            .find("a.langSelected")
            .removeClass("showCheckmark");
        $(this).addClass("showCheckmark");
    });
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $(".rmhc-nav").outerHeight();
    $(window).scroll(function (event) {
        didScroll = true;
    });
    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);
    function hasScrolled() {
        var st = $(this).scrollTop();
        // Make scroll more than delta
        if (Math.abs(lastScrollTop - st) <= delta) return;
        // If scrolled down and past the navbar, add class .nav-up.
        if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            $(".rmhc-nav").removeClass("nav-down").addClass("nav-up");
        } else {
            // Scroll Up
            if (st + $(window).height() < $(document).height()) {
                $(".rmhc-nav").removeClass("nav-up").addClass("nav-down");
            }
        }
        lastScrollTop = st;
    }
    // sticky nav ends
    /*Hamburger menu*/
    $("#nav-icon2").click(function () {
        $(this).attr("aria-expanded", true);
        $("nav").show("slide", { direction: "right" }, 500, function () {
            $(".close-hamberger").addClass("open");
        });
        $('<div class="nav-backdrop" tabindex="-1"></div>').insertBefore("nav");
        $("html,body").addClass("overflow-hidden");
        $("nav").attr("aria-hidden", "false");
        $(".title-bar").attr({ tabindex: -1, "aria-hidden": true });
        $("main").attr({ tabindex: -1, "aria-hidden": true });
    });
    $(".close-hamberger").click(function (e) {
        $("nav").hide("slide", { direction: "right" }, 500, function () {
            $(".close-hamberger").removeClass("open");
            $("#nav-icon2").focus();
        });
        $(".nav-backdrop").remove();
        $("html,body").removeClass("overflow-hidden");
        $(".nav-icon").attr("aria-expanded", false);
        $("nav").attr("aria-hidden", "true");
        $(".title-bar").removeAttr("tabindex aria-hidden");
        $("main").removeAttr("tabindex aria-hidden");
    });
    // Image Gallery script Start
    $("#example-tabs").on("change.zf.tabs", function () {
        $(".slider-for").slick("refresh");
        $(".slider-nav").slick("refresh");
    });
    var numSlick = 0;
    $(".slider-for").each(function () {
        numSlick++;
        $(this)
            .addClass("slider-" + numSlick)
            .on({
                beforeChange: function (
                    event,
                    slick,
                    current_slide_index,
                    next_slide_index
                ) {
                    var className = $(this).next().attr("class");
                    var newclassName = className.split(/\s/).join(".");
                    //console.log('.'+newclassName+ ' .slick-slide');
                    $("." + newclassName + " .slick-slide").removeClass(
                        "slick-main-active"
                    );
                    $(
                        "." +
                        newclassName +
                        " .slick-slide[data-slick-index=" +
                        next_slide_index +
                        "]"
                    ).addClass("slick-main-active");
                },
            })
            .slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                fade: true,
                adaptiveHeight: false,
                asNavFor: ".slider-nav.slider-" + numSlick,
            });
    });
    numSlick = 0;
    $(".slider-nav").each(function () {
        numSlick++;
        $(this)
            .addClass("slider-" + numSlick)
            .slick({
                vertical: false,
                slidesToShow: 10,
                slidesToScroll: 1,
                asNavFor: ".slider-for.slider-" + numSlick,
                arrows: false,
                focusOnSelect: true,
                infinite: false,
                adaptiveHeight: false,
                responsive: [
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            infinite: false,
                        },
                    },
                ],
            });
    });
    $(document).on("keydown", ".slick-arrow", function (e) {
        if (e.keyCode == 27) {
            $(".tabs a[aria-selected=true]").focus();
        }
    });
    $(".language-dropdown .selectedLang").click(function () {
        $(this).toggleClass("active").next().slideToggle();
        if ($(".has-children ul").is(":visible")) {
            $(".has-children ul").slideUp();
            $(".has-children").removeClass("active");
        }
        return false;
    });
    $(".lan-dropdown a").click(function () {
        var dataVal = $(this).data("value");
        $(this)
            .parent()
            .addClass("selected")
            .siblings("li")
            .removeClass("selected");
        //console.log(dataVal);
        $(".language-dropdown .selectedLang").text(dataVal).removeClass("active");
        $(this).parents(".lan-dropdown").slideUp();
        return false;
    });
    /*Available media Begins*/
    $(".available-media .align-middle ul>li>a>span").each(function () {
        $(this).css({
            top: -$(this).height() - 10,
        });
    });
    //Sticky
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 50) {
            //console.log('600');
            $("nav").addClass("sticky");
            $("nav.sticky div.rmhcLogo a").addClass("bg-white");
        } else {
            $("nav").removeClass("sticky");
            $("nav div.rmhcLogo a").removeClass("bg-white");
        }
    });
    //Horizontal file  gallery
    $(".horizontal-search-card-file").slick({
        slidesToShow: 4,
        //centerMode: true,
        prevArrow:
            '<div class="left-arrow"><button class="slick-prev" aria-label="Previous" type="button">Previous</button></div>',
        nextArrow:
            '<div class="right-arrow"><button class="slick-next" aria-label="Next" type="button">Next</button></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
        ],
    });
    //seach start
    $(".rmhc-search-btn").on("click", function (event) {
        var searchTerm = $("#search-term")[0].value;
        if (searchTerm != undefined && searchTerm.trim() != "") {
            window.open("/search?q=" + searchTerm, "_self");
            $(this).parents(".input-group").next(".error-msg").hide();
            $(this)
                .parents(".input-group")
                .children(".input-group-field")
                .removeAttr("aria-invalid aria-describedby");
        } else {
            $(this)
                .parents(".input-group")
                .next(".error-msg")
                .css("display", "block");
            $(this).parents(".input-group").children(".input-group-field").attr({
                "aria-invalid": true,
                "aria-describedby": "searchError",
            });
            $("#search-term").focus();
        }
    });
    $(".rmhc-mobile-search-btn").on("click", function (event) {
        var searchTerm = $("#mobile-search-term")[0].value;
        if (searchTerm != undefined && searchTerm.trim() != "") {
            window.open("/search?q=" + searchTerm, "_self");
            $(this).parents(".input-group").next(".error-msg").hide();
            $(this)
                .parents(".input-group")
                .children(".input-group-field")
                .removeAttr("aria-invalid aria-describedby");
        } else {
            $(this)
                .parents(".input-group")
                .next(".error-msg")
                .css("display", "block");
            $(this).parents(".input-group").children(".input-group-field").attr({
                "aria-invalid": true,
                "aria-describedby": "searchError-mobile",
            });
        }
    });
    $(".search-btn").click(function () {
        var searchTerm = $("#search-input")[0].value;
        if (searchTerm != undefined && searchTerm.trim() != "") {
            $(this).parents(".input-group").next(".error-msg").hide();
            $(this)
                .parents(".input-group")
                .children(".input-group-field")
                .removeAttr("aria-invalid aria-describedby");
            $("#result-info-for-screen-reader").focus();
        } else {
            $(this)
                .parents(".input-group")
                .next(".error-msg")
                .css("display", "block");
            $(this).parents(".input-group").children(".input-group-field").attr({
                "aria-invalid": true,
                "aria-describedby": "searchError-result",
            });
        }
    });
    $("#search-term").keydown(function (e) {
        if (e.which === 13) {
            $(".rmhc-search-btn").click();
        }
    });
    $("#mobile-search-term").keydown(function (e) {
        if (e.which === 13) {
            $(".rmhc-mobile-search-btn").click();
        }
    });
    $("header .search-toggle").click(function () {
        $(this).toggleClass("search-open");
        if ($(this).next().is(":visible")) {
            $(this).next().slideUp();
            $(this).next().attr("aria-hidden", true);
            $(this).attr("aria-expanded", false);
        } else {
            $(this).next().slideDown();
            $(this).next().attr("aria-hidden", false);
            $(this).attr("aria-expanded", true);
            $(this).next().find(".input-group-field").focus();
        }
    });
    //Window click hide dropdown
    $(document).mouseup(function (e) {
        if ($(e.target).closest(".search-dropdown").length === 0) {
            $(".search-dropdown").slideUp();
            $("header .search-toggle").removeClass("search-open");
            $(".search-dropdown").attr("aria-hidden", true);
            $(".search-toggle").attr("aria-expanded", false);
        }
    });
    $("header .search-toggle").keydown(function (e) {
        if (e.keyCode == 27) {
            $(this).next().slideUp();
            $(this).next().attr("aria-hidden", true);
            $(this).attr("aria-expanded", false);
        } else if (e.keyCode == 40) {
            $(this).next().slideDown();
            $(this).next().attr("aria-hidden", false);
            $(this).attr("aria-expanded", true);
            $(this).next().find(".input-group-field").focus();
        }
    });
    $(".close").keydown(function (e) {
        if (e.keyCode == 9) {
            $(".search-dropdown").slideUp();
            $(".search-dropdown").attr("aria-hidden", true);
            $(".search-dropdown").attr("aria-expanded", false);
            $("header .search-toggle").removeClass("search-open");
        }
    });
    $("#mainnav a").focus(function () {
        $(".search-dropdown").slideUp();
        $(".search-dropdown").attr("aria-hidden", true);
        $(".search-dropdown").attr("aria-expanded", false);
        $("header .search-toggle").removeClass("search-open");
    });
    $("#mainnav button").focus(function () {
        $(".search-dropdown").slideUp();
        $(".search-dropdown").attr("aria-hidden", true);
        $(".search-dropdown").attr("aria-expanded", false);
        $("header .search-toggle").removeClass("search-open");
    });
    //search end
    //Email validation for Sign up form start
    function ValidateEmail() {
        var email = document.getElementById("email-address").value;
        var lblError = document.getElementById("email-error");
        //lblError.innerHTML = "";
        var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        if (!expr.test(email)) {
            $(lblError).show();
        } else {
            $(lblError).hide();
        }
    }
    $(".sign-up-form #email-address").focus(function (e) {
        $("#email-error").fadeOut();
    });
    $(".sign-up-form #email-address").keyup(function (e) {
        ValidateEmail();
    });
    $(".sign-up-form").on("blur", "#email-address", function () {
        if ($("#email-error").is(":visible")) {
            setTimeout(function () {
                $("#email-error").fadeOut();
            }, 2000);
        }
    });
    $("#newsletter-form-submit").click(function () {
        ValidateEmail();
        if ($("#email-error").is(":visible")) {
            setTimeout(function () {
                $("#email-error").fadeOut();
            }, 2000);
        }
    });
    //Email validation for Sign up form end

    //For IE image
    var userAgent, ieReg, ie;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    ie = ieReg.test(userAgent);
    if (ie) {

        $(".img-container").each(function () {
            var $container = $(this),
                imgUrl = $container.find("img").prop("src");
            if (imgUrl) {
                $(".article-preview .item:first-child").find(".img-container").css("background-size", "contain").css("background-repeat", "no-repeat");
                $container
                    .css("backgroundImage", "url(" + imgUrl + ")")
                    .addClass("custom-object-fit");
            }
        });
    }
    // privacy bar start
    $(window).on("load resize scroll", function () {
        if ($(".privacy-bar-component").is(":visible")) {
            if ($(".privacy-bar-component").hasClass("privacy-is-visible")) {
                var privacyHeight = $(".privacy-bar-component").outerHeight();
                $("body").addClass("has-privecy").css("padding-top", privacyHeight);
            }
            //find chapter
            var windowHeight = $(window).height();
            $(".find-a-chapter-right").height(windowHeight - privacyHeight);
            $(".find-a-chapter-left").height(windowHeight - privacyHeight);
        } else {
            $(".privacy-bar-component").removeClass("privacy-is-visible");
            //find chapter
            var windowHeight = $(window).height();
            $(".find-a-chapter-right").height(windowHeight);
            $(".find-a-chapter-left").height(windowHeight);
        }
        $(".privacy-btn").click(function () {
            $(this)
                .parents(".privacy-bar-component")
                .removeClass("privacy-is-visible")
                .slideUp();
            $("body").removeAttr("style").removeClass("has-privecy");

            return false;
        });
    });
    //privacy bar end
  
    //For Contact Us Page Success Result Focus
    if ($(".ContactUsForm").length) {
        $(".ContactUsForm").on("click", 'input[type="submit"]', function () {
            $("html, body").animate(
                { scrollTop: $(".ContactUsForm").offset().top - 50 },
                500
            );
        });
    }
    //For Accordian
    var accordianTrigger = $(".accordin-block .accordion-title");
    $(accordianTrigger).each(function (i) {
        $(this).attr({
            "aria-expanded": false,
            "aria-controls": "sect-" + (i + 1),
            id: "accordion-" + (i + 1),
        });
    });
    $(".accordion-item .accordion-content").each(function (i) {
        $(this).attr({
            id: "sect-" + (i + 1),
            role: "region",
            "aria-labelledby": "accordion-" + (i + 1),
        });
    });
    $(accordianTrigger).click(function () {
        $(this).parent().toggleClass("is-active");
        var accContent = $(this).next(".accordion-content");
        $(".accordion-content")
            .not(accContent)
            .slideUp()
            .parent(".accordion-item")
            .removeClass("is-active");
        accContent.slideToggle();
        $(accordianTrigger).attr("aria-expanded", false);
        //$(accordianTrigger).removeAttr('aria-disabled');

        if ($(this).parent().hasClass("is-active")) {
            $(this).attr({
                "aria-expanded": true,
                //'aria-disabled':true
            });
        } else {
            $(this).attr("aria-expanded", false);
            $(this).removeAttr("aria-disabled");
        }
    });

    //Accordian KeyDown Event
    $(accordianTrigger).keydown(function (e) {
        if (e.keyCode == 38) {
            e.preventDefault();
            $(this).parent().prev("li").find("button").focus();
        } else if (e.keyCode == 40) {
            e.preventDefault();
            $(this).parent().next("li").find("button").focus();
        } else if (e.keyCode == 27) {
            $(this).parent().toggleClass("is-active");
            var accContent = $(this).next(".accordion-content");
            $(".accordion-content")
                .not(accContent)
                .slideUp()
                .parent(".accordion-item")
                .removeClass("is-active");
            accContent.slideToggle();
            $(accordianTrigger).attr("aria-expanded", false);
            $(accordianTrigger).removeAttr("aria-disabled");

            if ($(this).parent().hasClass("is-active")) {
                $(this).attr({
                    "aria-expanded": true,
                    "aria-disabled": true,
                });
            } else {
                $(this).attr("aria-expanded", false);
                $(this).removeAttr("aria-disabled");
            }
        }
    });
    //Contact Us
    $(".contactUs .button").on("click", function () {
        var captch = false;
        var requiredFiled = 0;
        var containsValue = 0;
        $(".contactUs")
            .find("input")
            .each(function () {
                if (
                    !$(this).hasClass("recaptchaclass") &&
                    $(this)[0].hasAttribute("data-val-required")
                ) {
                    requiredFiled = requiredFiled + 1;
                    if (
                        $(this).attr("class") != undefined &&
                        $(this).attr("class").trim() != "" &&
                        $(this).attr("class").trim() == "valid"
                    ) {
                        containsValue = containsValue + 1;
                    }
                }
            });
        $(".contactUs")
            .find("textarea")
            .each(function () {
                if ($(this)[0].hasAttribute("data-val-required")) {
                    requiredFiled = requiredFiled + 1;
                    if (
                        $(this).attr("class") != undefined &&
                        $(this).attr("class").trim() != "" &&
                        $(this).attr("class").trim() == "valid"
                    ) {
                        containsValue = containsValue + 1;
                    }
                }
            });
        if (
            requiredFiled > 0 &&
            containsValue > 0 &&
            requiredFiled == containsValue
        ) {
            captch = true;
        }
        if (captch == true && $(".recaptchaclass").val().length == 0) {
            grecaptcha.execute();
            return false;
        }
        return true;
    });

    //for Mobile nav accessbility

    $("body").append('<div class="dialog-overlay" tabindex="-1"></div>');
    if ($("nav").length > 0) {
        var navDialogEl = document.querySelector(".top-bar-right");
        var dialogOverlay = document.querySelector(".dialog-overlay");
        var myDialog = new Dialog(navDialogEl, dialogOverlay);
        myDialog.addEventListeners("#nav-icon2", ".close-hamberger");
    }
    if ($(".search-result-filter").length > 0) {
        var navDialogE2 = document.querySelector(".search-result-filter");
        var dialogOverlay1 = document.querySelector(".dialog-overlay");
        var myDialog1 = new Dialog(navDialogE2, dialogOverlay1);
        myDialog1.addEventListeners("#filter button", "#filter-close");
    }
    //Partner Card Keyboard Accessibility
    /* $(".partner-card-grid").hover(function () {
       $(this).toggleClass("is-active").siblings().removeClass("is-active"); 
    });Commented code for TECH - 1207 / TECH - 1300  Testing on mobile */

    // Web Accessibility Display  hover-content block on mouseover TECH-1207 / TECH-1300
    $(".partner-card-component").on("mouseover", function (e) {
        e.preventDefault();
        let isPartnerCardChild = $(".partner-card-grid").find(e.target).length;
        $(".partner-card-grid").children(".hover-content").css({ "transition": "opacity 0.05s, visibility s", "opacity": "0", "visibility": "hidden" });
        if (isPartnerCardChild === 1) {
            $(e.target).parents(".partner-card-grid").children(".hover-content").css({ "opacity": "1", "visibility": "visible" });
        } else if (isPartnerCardChild === 0) {
            $(".partner-card-grid").children(".hover-content").css({ "opacity": "0", "visibility": "hidden" });
            $(".partner-card-grid").children(".hover-content").removeClass("hover-content-active");
        }
    });

    // Web Accessibility Display  hover-content block on focus & click TECH-1207 / TECH-1300
    $(".partner-card-grid .img-wrapper a").on("click focus", function () {
        $(".partner-card-grid").children(".hover-content").removeClass("hover-content-active");
        $(this).parents(".partner-card-grid").children(".hover-content").addClass("hover-content-active");
    });
    $(".partner-card-grid").focus(function () {
        $(this).addClass("is-active").siblings().removeClass("is-active");
        $(".partner-card-component").removeClass("focus");
        $(this).parents(".partner-card-component").addClass("focus");
    });

    $(".partner-card-grid").keydown(function (e) {
        if (e.keyCode == 39) {
            $(this).next().focus();
        } else if (e.keyCode == 37) {
            $(this).prev().focus();
        }
    });
    $(".partner-card-component").each(function () {
        $(this)
            .find(".partner-card-grid")
            .last()
            .find("a")
            .focusout(function () {
                $(this).parents(".partner-card-grid").removeClass("is-active");
            });
        $(this)
            .find(".partner-card-grid")
            .last()
            .keydown(function (e) {
                if (e.keyCode == 9) {
                    $(".partner-card-component.focus").removeClass("focus");
                }
            });
    });
    //Partner Card Keyboard Accessibility end
    // Custom Skip To Link Fix
    $("header .skip-link").click(function (e) {
        e.preventDefault();
        $(":header:first").attr("tabindex", "-1").focus();
    });
    //end document ready
});
$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};
/* Adding a class to either enable focus styles for keyboard users or remove them for mouse users */
function handleFirstTab(e) {
    if (e.keyCode === 9) {
        document.body.classList.add("keyboard-navigation");
        window.removeEventListener("keydown", handleFirstTab);
        window.addEventListener("mousedown", handleMouseDownOnce);
    }
}
function handleMouseDownOnce() {
    document.body.classList.remove("keyboard-navigation");
    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
}
window.addEventListener("keydown", handleFirstTab);
//Function for the QueryParam
function getQueryParamValue(queryKey) {
    var queryValue = "";
    var urlParams = new URLSearchParams(location.search);
    for (const [paramKey, paramValue] of urlParams) {
        if (paramKey == queryKey) {
            queryValue = paramValue;
        }
    }
    return queryValue;
}
//Function for merge the URL and link href query param 
function mergeLinkURLQueryParam() {
    $(document).ready(function () {
        const urlParams = new URLSearchParams(window.location.search);
        const pramData = [];
        urlParams.forEach((value, key) => {
            pramData.push({ key: key, value: value });
        });
        const paramsArray = [];
        $('a[href]').each(function () {
            var href = $(this).attr('href');
            if (href.startsWith("/") || href.indexOf(window.location.origin) === 0) {
                if (href.indexOf('?') !== -1) {
                    var orghref = href.split('?')[0];
                    var queryString = href.split('?')[1];
                    var queryParams = queryString.split('&');
                    queryParams.forEach(function (param) {
                        var keyValue = param.split('=');
                        var key = decodeURIComponent(keyValue[0]);
                        var value = decodeURIComponent(keyValue[1]);
                        paramsArray.push({ key: key, value: value });
                    });
                    var mergedParams = [...paramsArray, ...pramData];
                    const mergededData = {};
                    mergedParams.forEach(item => {
                        if (item.key !== "" && item.value !== "undefined") {
                            mergededData[item.key] = item.value;
                        }
                    });
                    const mergedParamsData = Object.entries(mergededData).map(([key, value]) => ({ key, value }));
                    // Convert array to query string
                    updatedData = mergedParamsData.map(item => `${encodeURIComponent(item.key)}=${encodeURIComponent(item.value)}`).join('&');
                    href = orghref + '?' + updatedData;
                } else {
                    href += location.search;
                }
            }
            $(this).attr('href', href);
        });
    });
}