$(document).ready(function() { 
    //for IE
    var elements = $('.scrolljack-map-wrapper .grid-x');
    var elements2 = $('.map-container');
    Stickyfill.add(elements);  
    Stickyfill.add(elements2); 

//    $(window).on('load resize scroll',function(){
//     $('.map-slide').each(function(){       
//         $(this).height($(this).height());
//     }) 
//    })
     //for wow js animation repeat
       WOW.prototype.addBox = function(element) {
        this.boxes.push(element);
       };

       // Init WOW.js and get instance
      var wow = new WOW();
      wow.init();

     
 if($('.scrolljack-map-wrapper').length){    
    var paginationList = '';
    $('.map-slide').each(function(i){
        $(this).addClass('section')
        .attr({
            "data-index":i+1,
            "id":"section-"+(i+1)
    });
    paginationList += "<li><a role='link' tabindex='-1' data-index='"+(i+1)+"' aria-label='Panel-" + (i+1) + "' href='#section-" + (i+1) + "'></a></li>";
    if ($('ul.onepage-pagination').length < 1) $(".scrolljack-map-wrapper").append("<ul class='onepage-pagination' id='nav'></ul>");
    $('ul.onepage-pagination').html(paginationList);
    })
    if($('.map-img').length){
        $('.map-img').each(function(i){
            $(this).addClass('section')
        .attr("data-index",i+1 );
        })
    }

    // $(window).on('scroll',function(){
        $('.onepage-pagination li').on('scroll', function() {
        var currentActive =  $('.onepage-pagination li.current a').attr('data-index');             
        $('.map-img').removeClass('current');
        $('.map-img[data-index='+currentActive+']').addClass('current');
       })   
       
       $('.onepage-pagination li').on('classChanged', function() {
        var currentActive =  $('.onepage-pagination li.current a').attr('data-index');             
        $('.map-img').removeClass('current');
        $('.map-img[data-index='+currentActive+']').addClass('current');
       }) 

       $('.onepage-pagination a').on('focus', function() {
       $(this).trigger('click');
        var currentActive =  $('.onepage-pagination li.current a').attr('data-index');             
        $('.map-img').removeClass('current');
        $('.map-img[data-index='+currentActive+']').addClass('current');
       })

       $('.onepage-pagination a').keydown(function(e) {
           if(e.keyCode == 38){
               $(this).parent('li').prev('li').find('a').focus();
           }else  if(e.keyCode == 40){
            $(this).parent('li').next('li').find('a').focus();
        }
        
        })

       $('#nav').onePageNav(); 
 }
 // Scroll event check

//on scroll fixed nav
if($('.scrolljack-map-wrapper').length){ 
$(window).scroll(function (event) {
    var scroll = $(window).scrollTop();
    var offsetTop = $('.offset-div2').offset().top-50;    
    // Activate sticky for IE if scrolltop is more than 20px
    if ( scroll > offsetTop) {
        $('#nav').addClass('show-nav nav-animation'); 
    }else{
        $('#nav').removeClass('show-nav');         
    }
    
});
//on bottom of scroll detect
$(window).bind('scroll', function() {
    if($(window).scrollTop() >= $('.scrolljack-map-wrapper').offset().top + $('.scrolljack-map-wrapper').outerHeight()+100 - window.innerHeight) {
        $('#nav').removeClass('show-nav');   
    }
});
}
 });  
 (function( func ) {
    $.fn.addClass = function() { // replace the existing function on $.fn
        func.apply( this, arguments ); // invoke the original function
        this.trigger('classChanged'); // trigger the custom event
        return this; // retain jQuery chainability
    }
})($.fn.addClass); // pass the original function as an argument

(function( func ) {
    $.fn.removeClass = function() {
        func.apply( this, arguments );
        this.trigger('classChanged');
        return this;
    }
})($.fn.removeClass);